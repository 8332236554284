
import Vue from 'vue';
import { RouterLink } from 'vue-component-router';
import {
  faCalendarAlt,
  faMapMarkerAlt
} from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { normalizedEventDate } from '@/shared/util';
import { GetEventsQuery } from '@/generated/graphql-types';
import { ArrayElement } from '@/shared/util/types';

type Event = ArrayElement<GetEventsQuery['events']>;

library.add(faMapMarkerAlt, faCalendarAlt);

interface Computed {
  eventTime: string;
}
interface Props {
  event: Event;
}

export default Vue.extend<{}, {}, Computed, Props>({
  name: 'EventCard',
  components: {
    RouterLink
  },
  props: {
    event: {}
  },
  computed: {
    eventTime() {
      return normalizedEventDate(
        this.event.startDate,
        this.event.endDate,
        this.event.timeZone
      );
    }
  }
});
