import { DocumentNode, OperationDefinitionNode } from 'graphql';
import Vue, {
  Component,
  ComponentOptions,
  FunctionalComponentOptions
} from 'vue';
import parser from 'another-name-parser';
import range from 'lodash/range';
import map from 'lodash/map';
import PhoneNumber from 'awesome-phonenumber';
import * as countries from 'country-list';
import DateTimezone from 'date-timezone';
import tz from 'timezone/loaded';
import {
  Address,
  ExtendedPersonFragment,
  PhoneType
} from '@/generated/graphql-types';

export function getOperationType(query: DocumentNode) {
  const definitionNode = query.definitions[0] as OperationDefinitionNode;
  return definitionNode.operation;
}

function isFunctional(arg: any): arg is FunctionalComponentOptions {
  return arg.functional !== undefined;
}

export function getOptions(component: Component): ComponentOptions<Vue> {
  if (typeof component === 'function') {
    // @ts-expect-error "options" are not provided in types
    return component.options as ComponentOptions<Vue>;
  } else {
    if (isFunctional(component)) {
      throw Error('Functional Components Not Supported');
    }

    return component as ComponentOptions<Vue>;
  }
}

export function normalizeProps(props = {}) {
  if (Array.isArray(props)) {
    return props.reduce(
      (normalizedProps, prop) => ({ ...normalizedProps, [prop]: {} }),
      {}
    );
  }

  return { ...props };
}

export function splitName(name: string) {
  const result = parser(name);
  return {
    firstName: result.first
      ? `${result.first}${result.middle ? ` ${result.middle}` : ''}`
      : '',
    lastName: result.last
      ? `${result.last}${result.suffix ? ` ${result.suffix}` : ''}`
      : '',
    original: name
  };
}

export function validateName(name: string | null | undefined) {
  const { firstName, lastName } = splitName(name || '');
  return Boolean(firstName.trim() && lastName.trim());
}

export function graduationYears() {
  const date = new Date();
  const schoolYear =
    date.getMonth() > 5 ? date.getFullYear() + 1 : date.getFullYear();
  const school = range(schoolYear, schoolYear + 8).reverse();
  return school.map((year) => ({ ...getGrade(year) }));
}

export function getGrade(year: number | null) {
  if (!year) return { year, grade: '(Unknown)' };

  // Stolen from https://ecommerce.shopify.com/c/ecommerce-design/t/ordinal-number-in-javascript-1st-2nd-3rd-4th-29259
  function ordinal(n: number) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  const schoolYear =
    new Date().getMonth() > 5
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();

  const grade = schoolYear - year;

  if (12 + grade > 12) {
    return { year, grade: 'Alumnus' };
  }

  let desc = '';
  if (12 + grade > 0) {
    desc = ordinal(12 + grade) + ' grade';
  } else if (12 + grade === 0) {
    desc = 'Kindergarten';
  } else if (12 + grade === -1) {
    desc = 'Pre-K';
  }

  return { year, grade: desc };
}

function isValueless(x: any) {
  return x === null || x === undefined;
}

export function getCurrentEmail(
  emails: ExtendedPersonFragment['EmailAddresses']
) {
  const filtered = emails.filter((x) => x.primary);
  let actual = filtered.length
    ? filtered
    : emails.filter((x) => isValueless(x.invalid) || !x.invalid);
  // For now, if there is no primary email, and no valid email, then we just take all emails
  actual = actual.length ? actual : emails.slice();
  const result = actual.sort((a, b) => {
    const firstDateUpdated = new Date(a.dateUpdated).getTime();
    const secondDateUpdated = new Date(b.dateUpdated).getTime();
    if (firstDateUpdated === secondDateUpdated) {
      return (
        new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      );
    }
    return secondDateUpdated - firstDateUpdated;
  });
  return result.length > 0 ? result[0] : null;
}

export function formatAddressToString(
  address: Partial<Address> | null | undefined
) {
  if (!address) {
    return '';
  }
  const { city, state, country, zipCode, street, street2 } = address;
  return `${street ? ` ${street}` : ''}${street2 ? ` ${street2}` : ''}${
    city ? ` ${city}` : ''
  }${state ? ` ${state}` : ''}${zipCode ? ` ${zipCode}` : ''}${
    country ? ` ${country}` : ''
  }`.trim();
}

export function getCurrentCellNumber(phones: ExtendedPersonFragment['Phones']) {
  if (!phones || !phones.length) return null;
  const cells = phones
    .filter(
      (p) =>
        p.type === PhoneType.Mobile && (isValueless(p.primary) || p.primary)
    )
    .sort((a, b) => {
      const firstDateUpdated = new Date(a.dateUpdated).getTime();
      const secondDateUpdated = new Date(b.dateUpdated).getTime();
      if (firstDateUpdated === secondDateUpdated) {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      }
      return secondDateUpdated - firstDateUpdated;
    });
  return cells.length > 0 ? cells[0] : null;
}

export function getCurrentLandlineNumber(
  phones: ExtendedPersonFragment['Phones']
) {
  if (!phones || !phones.length) return null;
  const landlineNumbers = phones
    .filter(
      (p) =>
        p.type === PhoneType.Landline && (isValueless(p.primary) || p.primary)
    )
    .sort((a, b) => {
      const firstDateUpdated = new Date(a.dateUpdated).getTime();
      const secondDateUpdated = new Date(b.dateUpdated).getTime();
      if (firstDateUpdated === secondDateUpdated) {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      }
      return secondDateUpdated - firstDateUpdated;
    });
  return landlineNumbers.length > 0 ? landlineNumbers[0] : null;
}

export function getCurrentAddress(addresses: Address[]) {
  const filtered = addresses
    .filter((x) => isValueless(x.primary) || x.primary)
    .sort((a, b) => {
      const firstDateUpdated = new Date(a.dateUpdated).getTime();
      const secondDateUpdated = new Date(b.dateUpdated).getTime();
      if (firstDateUpdated === secondDateUpdated) {
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      }
      return secondDateUpdated - firstDateUpdated;
    });
  return (
    filtered[0] || { street: '', city: '', state: '', zipCode: '', country: '' }
  );
}

export const normalizedEventTime = (
  startDate: string,
  endDate: string,
  timeZone: string
) => {
  return `${tz(tz(startDate), '%R%p', timeZone)} - ${tz(
    tz(endDate),
    '%R%p',
    timeZone
  )}`;
};

export const normalizedEventDate = (
  startDate: string,
  endDate: string,
  timeZone: string
) => {
  const formattedStartDate = dateFormat(startDate);
  const formattedEndDate = dateFormat(endDate);
  const time = normalizedEventTime(startDate, endDate, timeZone);

  return `${time}, ${formattedStartDate}${
    formattedStartDate !== formattedEndDate ? ` - ${formattedEndDate}` : ``
  }`;
};

export function dateFormat(ISOdate: string): string | null {
  if (!ISOdate || typeof ISOdate !== 'string' || !ISOdate.includes('T'))
    return null;
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const [year, month, day] = new Date(ISOdate)
    .toISOString()
    .split('T')[0]
    .split('-');
  return monthNames[+month - 1] + ' ' + day + ', ' + year;
}

export function phoneFormat(phone: any, countryISO = ''): string {
  if (!phone) return '';
  const israeliPhoneNumberPattern = /^0\d([\d]{0,1})([-]{0,1})\d{7}$/;
  const pn = new PhoneNumber(phone, countryISO || '');
  if (pn.isPossible()) {
    if (
      pn.getRegionCode() === 'IL' &&
      new RegExp(israeliPhoneNumberPattern).test(phone)
    ) {
      return pn.getNumber('international');
    }
    return pn.getNumber('national');
  }

  const getISO = countries.getCode(countryISO || '');

  if (getISO) {
    const pn = new PhoneNumber(phone, getISO);
    if (pn.isPossible() && new RegExp(israeliPhoneNumberPattern).test(phone)) {
      if (pn.getRegionCode() === 'IL') {
        return pn.getNumber('international');
      }
      return pn.getNumber('national');
    }
  }

  const part1 = phone.toString().split('').splice(0, 3).join('');
  const part2 = phone.toString().split('').splice(3, 3).join('');
  const part3 = phone.toString().split('').splice(6).join('');

  return `(${part1}) ${part2}-${part3}`;
}

export function cleanNumber(c: string | number) {
  if (!c) return '';
  return c
    .toString()
    .trim()
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace('-', '')
    .split(' ')
    .join('');
}

export function validateNumber(value: string, allowEmptyValue?: boolean) {
  if (!value) return !!allowEmptyValue;
  return /^\d+$/.test(value);
}

export function isIsoDate(str: string): boolean {
  if (!str) return false;
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
}

export function isValidDateYYYMMDDstring(dateString: string) {
  if (!dateString || typeof dateString !== 'string') return false;
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  const d = new Date(dateString);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

export function asUTCWithZerotime(date: Date | string): string | null {
  let result = null;
  if (!date) return null;
  DateTimezone.setGlobalTimezone('Etc/Greenwich');

  if (typeof date === 'string') {
    if (isIsoDate(date)) {
      const [year, month, day] = map(date.split('T')[0].split('-'), Number);
      result = new DateTimezone.DateTimezone(
        year,
        month - 1,
        day,
        0,
        0
      ).toISOString();
    } else if (isValidDateYYYMMDDstring(date)) {
      const [year, month, day] = map(date.split('-'), Number);
      result = new DateTimezone.DateTimezone(
        year,
        month - 1,
        day,
        0,
        0
      ).toISOString();
    }
  } else if (date instanceof Date) {
    const [day, month, year] = [
      date.getDate(),
      date.getMonth(),
      date.getFullYear()
    ];
    result = new DateTimezone.DateTimezone(
      year,
      month,
      day,
      0,
      0
    ).toISOString();
  }

  return result;
}

export function asLocalISO(date: Date): string {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() - offset).toISOString();
}

export function calculateAge(birthdate: Date) {
  if (!birthdate) {
    return 0;
  }
  const bDate = Date.parse(asUTCWithZerotime(birthdate) as string);
  return (
    new Date(Date.now() - bDate).getUTCFullYear() - new Date(0).getUTCFullYear()
  );
}

export function isJSU(location: string, eventSubTypeId?: number) {
  return location.includes('jsu.') || eventSubTypeId === 290;
}

export function decodeURLParams(search: string): { [key: string]: any } {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const split = hash.indexOf('=');

    if (split < 0) {
      return Object.assign(params, {
        [hash]: null
      });
    }

    const key = hash.slice(0, split);
    const val = hash.slice(split + 1);

    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
}
