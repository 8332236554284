const lang = {
  Root_Authenticating: {
    en: 'Authenticating',
    es: 'Autenticando'
  },
  Root_Redirecting: {
    en: 'Redirecting',
    es: 'Redireccionando'
  },
  Root_NoPermission: {
    en: "You don't have permissions to access this site",
    es: 'No tenés permiso para acceder a este sitio'
  },
  Root_Click: {
    en: 'Click',
    es: 'Hacé click'
  },
  Root_Here: {
    en: 'here',
    es: 'aquí'
  },
  Root_DifferentLogin: {
    en: 'to login with a different user',
    es: 'para loguearte con otro usuario'
  },
  Root_SelectRegion: {
    en: 'Please select a region',
    es: 'Por favor seleccioná una región'
  },
  Root_Events_NoEvents: {
    en: 'There are no events',
    es: 'No hay actividades'
  },
  Root_TeenRoot_ErrorLink: {
    en: 'There was an error, please speak to your event coordinator as there is an issue with the link',
    es: 'Hubo un error, por favor hablá con el coordinador de la actividad ya que hay un problema con el link'
  },
  Root_TeenRoot_ErrorCoordinator: {
    en: 'There was an error, please speak to your event coordinator',
    es: 'Hubo un error, por favor hablá con el coordinador de la actividad'
  },
  Kiosk_Email: {
    en: 'Please enter your email',
    es: 'Por favor ingresá tu email'
  },
  Kiosk_Error: {
    en: 'Please enter your email',
    es: 'Hubo un error, por favor hablá con el coordinador de la actividad'
  },
  Kiosk_Next: {
    en: 'Next',
    es: 'Continuar'
  },
  Kiosk_Clear: {
    en: 'Clear',
    es: 'Limpiar'
  },
  CreateTeen_Rest: {
    en: 'Please enter the rest of your information',
    es: 'Por favor completá el resto de tu datos'
  },
  CreateTeen_Name: {
    en: 'Name',
    es: 'Nombre'
  },
  CreateTeen_Email: {
    en: 'Email',
    es: 'Email'
  },
  CreateTeen_Gender: {
    en: 'Gender',
    es: 'Sexo'
  },
  CreateTeen_Zip: {
    en: 'Zip',
    es: 'Código Postal'
  },
  CreateTeen_Grade: {
    en: 'Grade',
    es: 'Año'
  },
  CreateTeen_Cellphone: {
    en: 'Cellphone',
    es: 'Celular'
  },
  CreateTeen_Birthdate: {
    en: 'Birthdate',
    es: 'Fecha de Nacimiento'
  },
  CreateTeen_School: {
    en: 'School',
    es: 'Colegio'
  },
  CreateTeen_Submitting: {
    en: 'Submitting',
    es: 'Guardando'
  },
  CreateTeen_Submit: {
    en: 'Submit',
    es: 'Guardar'
  },
  CreateTeen_Male: {
    en: 'Male',
    es: 'Masculino'
  },
  CreateTeen_Female: {
    en: 'Female',
    es: 'Femenino'
  },
  Finished_ThankYou: {
    en: 'Thank you',
    es: 'Gracias'
  },
  Finished_ForAttending: {
    en: 'for attending',
    es: 'por asistir a'
  },
  MarkAttendance_Register: {
    en: 'Please click below to register your attendance at ',
    es: 'por favor clickeá debajo para registrar tu asistencia a '
  },
  MarkAttendance_Hi: {
    en: 'Hi',
    es: 'Hola'
  },
  MarkAttendance_Attended: {
    en: "I'm attending",
    es: 'Yo asistí a'
  },
  GetParentData_Info: {
    en: 'Please enter contact information for at least one parent',
    es: 'Por favor ingrese la información de contacto de al menos un padre'
  },
  GetParentData_NameFather: {
    en: "Father's Name",
    es: 'Nombre del Padre'
  },
  GetParentData_EmailFather: {
    en: "Father's Email",
    es: 'Email del Padre'
  },
  GetParentData_NameMother: {
    en: "Mother's Name",
    es: 'Nombre del la Madre'
  },
  GetParentData_EmailMother: {
    en: "Mother's Email",
    es: 'Email del la Madre'
  },
  ParentFound_Address: {
    en: 'This address is associated with a parent in our system, please select a child',
    es: 'Este email está asociado con un padre en nuestro Sistema, por favor seleccioná un hijo'
  },
  ParentFound_NoChildren: {
    en: 'There are no children associated.',
    es: 'No hay niños asociados.'
  },
  MarkConsent_MediaRelease: {
    en: 'Media Release',
    es: 'Comunicado de prensa'
  },
  MarkConsent_PleaseComplete: {
    en: 'Please complete this form to give us permission to take photos/videos of you and/or your children when you participate in',
    es: 'Por favor completá este formulario para darnos permiso de tomar fotos/videos de vos y/o tus hijos cuando participes y/o participen de actividades de '
  },
  MarkConsent_Publicity: {
    en: 'events and to use those photos/videos in our printed and online publicity',
    es: 'y usar esas fotos/videos en nuestra publicidad impresa y online '
  },
  MarkConsent_Authorize: {
    en: 'I hereby authorize',
    es: 'Por la presente autorizo a'
  },
  MarkConsent_Affiliates: {
    en: 'and its affiliates, employees and agents, (together',
    es: 'y sus afiliados, empleados y representantes, (juntos'
  },
  MarkConsent_Photograph: {
    en: 'to take photographs and/or video of me (or my child) when I (or my child) participate at',
    es: 'a tomar fotos y/o videos de mi (o mi hijo/a) cuando yo (o mi hijo/a) participe de actividades de'
  },
  MarkConsent_Grant: {
    en: 'events.  I grant',
    es: 'Concedo a'
  },
  MarkConsent_Rights: {
    en: 'full rights to use and share the images resulting from the photographs and/or videos and any reproductions thereof (the “Recording”), in whole or in part, along with my (or my child’s) name, biographical information and quotations for any purpose including fundraising, publicity, advertising, or on social media, all without further obligation or compensation.  I hereby waive any right of review or approval regarding the Recording and',
    es: '}} la totalidad de los derechos de usar y compartir las imágenes resultantes de las fotos y/o videos y cualquier reproducción cual sea (la “Grabación”), completa o en parte, junto con mi nombre (o el de mi hijo/a), información biográfica y testimonios para cualquier propósito incluyendo recaudación de fondos, publicidad, o social media, todo sin una futura obligación o compensación. Por la presente rechazo cualquier derecho de revisión o aprobación respecto de la Grabación y'
  },
  MarkConsent_Discretion: {
    en: 'shall have the right to edit or delete the Recording in its sole discretion. I understand that all rights in and to the Recording shall be',
    es: 'tendrá el derecho de editar o eliminar la Grabación a su sola discreción. Entiendo que todos los derechos de la Grabación serán propiedad única y absoluta de'
  },
  MarkConsent_Property: {
    en: '’s sole and absolute property. I release and forever discharge',
    es: 'Libero y eximo de manera definitiva a'
  },
  MarkConsent_Action: {
    en: 'from all causes of action, suits, or liability of any kind arising from or in connection with the use or publication of any Recording of me or my child made in connection with my participation in',
    es: 'de toda causa, cargo, demanda, pena o responsabilidad de cualquier tipo proveniente o en conexión con el uso o la publicación de cualquier Grabación mía o de mi hijo/a en conexión a mi participación en actividades de'
  },
  MarkConsent_Waive: {
    en: 'events. I waive any right of privacy associated with the Recording and I waive any right to compensation related to the use of the Recording.',
    es: 'Rechazo cualquier derecho de privacidad asociado con la Grabación y rechazo cualquier derecho de compensación con relación al uso de la Grabación'
  },
  MarkConsent_Read: {
    en: 'I HAVE READ THIS AGREEMENT AND AM FULLY AWARE OF ITS CONTENTS, INCLUDING THE RELEASE THAT I HAVE AGREED TO HEREIN',
    es: 'HE LEÍDO ESTE ACUERDO Y SOY TOTALMENTE CONSCIENTE DE SU CONTENIDO'
  },
  MarkConsent_Warrent: {
    en: 'I warrant that I am at least eighteen years of age (or, if not, that my parent or legal guardian has agreed to the foregoing and signed below) and that I have the full and unrestricted authority to enter into a contract for myself (or, if not, that my parent or legal guardian has agreed to the foregoing and signed below)',
    es: 'Garantizo que soy mayor de 18 años de edad y que tengo la completa e irrestringida autoridad de ingresar en un contrato por mi cuenta (o, si no, que mi padre o tutor ha aceptado todo lo anterior y firmado de bajo)'
  },
  MarkConsent_Effect: {
    en: 'If any provision of this agreement is held to be unenforceable, the remainder of it shall continue to be in full force and effect',
    es: 'Si alguna disposición de este acuerdo se considera inaplicable, el resto de la misma seguirá en pleno vigor y efecto'
  },
  MarkConsent_Terms: {
    en: 'I agree to the Terms and Conditions of the',
    es: 'Acepto los Términos y Condiciones de la '
  },
  MarkConsent_Certify: {
    en: 'I hereby certify that I agree to the media consent',
    es: 'Por la presente certifico que acepto el consentimiento de Medios'
  },
  MarkConsent_PrivacyPolicy: {
    en: 'Privacy Policy',
    es: 'Política de Privacidad'
  },
  Kiosk_Footer_LearnMore: {
    en: 'To learn more about our privacy policy please visit',
    es: 'Para obtener más información sobre nuestra política de privacidad, visite'
  },
  MarkConsent_ParentsWillBeNotified: {
    en: 'An email will be sent to the parent email address(es) provided, with a link to digitally sign the media and liability waivers.',
    es: 'Se enviará un correo electrónico a la(s) dirección(es) de correo electrónico principal proporcionada, con un link para firmar digitalmente los medios y las exenciones de responsabilidad.'
  },
  GraduationYearPicker_GraduationYear: {
    en: 'Graduation Year',
    es: 'Año de Secundaria'
  }
};

export default lang;

const getKeys = <T extends object>(o: T): Array<keyof T> =>
  <Array<keyof T>>Object.keys(o);
function getByLang(language: 'en' | 'es') {
  return getKeys(lang)
    .map((k) => ({ [k]: lang[k][language] }))
    .reduce((p, c) => ({ ...c, ...p }), {});
}

function getLangKeyForRegion(regionId: number) {
  if (regionId === 29 || regionId === 32) {
    return 'es';
  }
  return 'en';
}

function getLangForRegion(regionId: number) {
  return getByLang(getLangKeyForRegion(regionId));
}
export { getLangForRegion };
