var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper"},[_c('header',[_c('RouterLink',{staticClass:"logo",attrs:{"to":"/"}},[(!_vm.isJSU)?_c('img',{attrs:{"src":"https://res.cloudinary.com/ouinternal/image/upload/v1539591616/logos/i3logo.png","alt":"NCSY"}}):_c('img',{staticClass:"jsu",attrs:{"src":"https://res.cloudinary.com/ncsy/image/upload/v1473780252/logos/JSU-logo.png","alt":"JSU"}})])],1),(_vm.event && _vm.event.eventId)?_c('ScrollablePanel',{directives:[{name:"fullheight",rawName:"v-fullheight"}]},[_c('main',[_c('div',{staticClass:"kiosk"},[_c('div',{staticClass:"event-details"},[_c('div',{staticClass:"event-details__thumb"},[_c('img',{attrs:{"src":`https://res.cloudinary.com/orthodox-union/image/fetch/c_fill,f_auto/` +
                _vm.eventImage}})]),_c('div',{staticClass:"event-details__title"},[_vm._v(_vm._s(_vm.event.eventName))])]),(_vm.component !== 'Finished' && !_vm.externalEmail)?_c('div',{staticClass:"email-input"},[_c('div',{staticClass:"form-row"},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.lang.Kiosk_Email)+" "),_c('span',{staticClass:"ast"},[_vm._v("*")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('UIInput',{staticStyle:{"margin-right":"10px"},attrs:{"type":"email"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEmailChanged.apply(null, arguments)},"blur":_vm.handleEmailChanged},model:{value:(_vm.localEmail),callback:function ($$v) {_vm.localEmail=$$v},expression:"localEmail"}}),_c('UIButton',{on:{"click":_vm.handleEmailChanged}},[_vm._v(_vm._s(_vm.lang.Kiosk_Next))])],1)])])]):_vm._e(),(_vm.email)?_c('div',[_c('main',{staticClass:"kiosk__main"},[_c(_vm.component,_vm._g(_vm._b({tag:"component",attrs:{"attendedEvent":_vm.attendedEvent,"registeredEvent":_vm.registeredEvent,"selectChild":_vm.selectChild,"selectEmail":_vm.selectEmail,"isJSU":_vm.isJSU}},'component',_vm.$props,false),_vm.$listeners))],1),_c('footer',{staticClass:"kiosk__footer"},[(
                _vm.component !== 'Loading' &&
                (_vm.component !== 'Finished' ||
                  (_vm.component === 'Finished' && _vm.root)) &&
                !_vm.externalEmail
              )?_c('UIButton',{staticClass:"inverted stripped",on:{"click":function($event){$event.preventDefault();return _vm.clearEmail.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.component === 'Finished' && _vm.root ? _vm.lang.Kiosk_Next : _vm.lang.Kiosk_Clear)+" ")]):_vm._e()],1)]):_vm._e()])]),_c('p',{staticClass:"learn-more"},[_vm._v(" "+_vm._s(_vm.lang.Kiosk_Footer_LearnMore)+" "),_c('a',{attrs:{"href":`https://${_vm.isJSU ? 'jsu' : 'ncsy'}.org/privacy`}},[_vm._v(_vm._s(_vm.isJSU ? 'jsu' : 'ncsy')+".org/privacy")])])]):(_vm.eventLoading)?_c('Loading'):_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.lang.Kiosk_Error)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }